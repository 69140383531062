import { Match, Show, Suspense, Switch } from 'solid-js';
import { Link, Picture } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { gql } from '../../graphql';
import type { FacilityHeaderFragment } from '../../graphql';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	bordered?: boolean;
	facility: Partial<FacilityHeaderFragment>;
	linked?: boolean;
	showHero?: boolean;
}>;

export function FacilityHeader(props: Props) {
	return (
		<header
			class={twJoin(
				'grid grid-cols-12 items-center gap-4 pb-6 sm:pb-8 lg:gap-8',
				props.bordered && 'mb-6 border-b border-neutral-500 sm:mb-8',
			)}
		>
			<div class={twJoin('flex flex-col gap-2 md:gap-4', props.showHero ? 'col-span-8' : 'col-span-12 lg:col-span-8')}>
				<h1>
					<Suspense fallback="…">
						<Switch>
							<Match when={props.linked === false}>
								<span
									class={twJoin(
										'font-semibold',
										props.showHero ? 'text-base sm:text-2xl lg:text-4xl' : 'text-3xl md:text-5xl',
									)}
								>
									{props.facility.name}
								</span>
							</Match>
							<Match when>
								<Link href={`/course/${props.facility.slug}`} class="hover:text-brand-600 hover:underline">
									<span
										class={twJoin(
											'font-semibold',
											props.showHero ? 'text-base sm:text-2xl md:text-3xl lg:text-4xl' : 'text-3xl md:text-5xl',
										)}
									>
										{props.facility.name}
									</span>
								</Link>
							</Match>
						</Switch>
					</Suspense>
				</h1>
				<Show when={props.facility.metadata?.address}>
					<address class="text-sm not-italic text-neutral-800" aria-label={`Address for ${props.facility.name}`}>
						{[
							props.facility.metadata!.address!.street,
							props.facility.metadata!.address!.city,
							[props.facility.metadata!.address!.state, props.facility.metadata!.address!.postalCode].join(' '),
							props.facility.metadata!.address!.country,
						].join(', ')}
					</address>
				</Show>
			</div>

			<Switch>
				<Match when={props.showHero && props.facility?.metadata?.hero?.url}>
					{(heroUrl) => (
						<div class="col-span-4 flex justify-end">
							<div class="w-auto shrink grow-0 basis-24 md:basis-36">
								<Picture
									src={heroUrl()}
									sizes={[
										[200, 200],
										[100, 100],
									]}
									class="aspect-square w-full max-w-max rounded-lg object-cover"
								/>
							</div>
						</div>
					)}
				</Match>
				<Match when>
					<div class="col-span-12 lg:col-span-4">{props.children}</div>
				</Match>
			</Switch>
		</header>
	);
}

export const fragment = gql(`
fragment FacilityHeader on Facility {
	id
	slug
	name
	metadata {
		hero {
			url
		}
		address {
			street
			city
			state
			postalCode
			country
		}
	}
}`);
